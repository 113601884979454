Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcustomonboardingflowcreator1";
exports.labelBodyText = "cfcustomonboardingflowcreator1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getQuestionStatsEndpoint = "bx_block_landingpage2/landing_page_questions/get_variant_form_data";
exports.signInPatient = "account_block/accounts"
exports.genCoverage = "coverage"
exports.getStateApiEndPoint = "account_block/patients/states"
exports.postTherapyDataApiEndPoint = "account_block/patients"
exports.postAPiMethod = "POST";
exports.putApiMethod = "PUT";
exports.matchMakingApiEndp = "bx_block_landingpage2/landing_page_questions/get_multiple_page_instance";

// Customizable Area End