import React from 'react';

// Customizable Area Start
import {
  Box,
  styled,
} from '@mui/material';
import { HeaderLogoImg, GroupWellness, right_img} from './assets';
import PageNotFound from "../../../components/src/PageNotFound.web";
import ImageLoader from '../../../components/src/ImageLoader.web';

// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController";
class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleButtonElm = (item: {
    primary_button_text: string,
    primary_link_url: string,
    primary_button_text_1: string,
    primary_link_url_1: string,
    external_link: boolean,
    external_link_1: boolean
  }, sectionType: string = "") => {
    
    const isExternalLink = sectionType ? item.external_link_1 : item.external_link;
    const buttonText = sectionType ? item.primary_button_text_1 : item.primary_button_text;
    const linkUrl = sectionType ? item.primary_link_url_1 : item.primary_link_url;
  
    return isExternalLink ? (
      <a className="button" data-testid="navigate-button" href={linkUrl}>
        {buttonText}
      </a>
    ) : (
      <button className="button" data-testid="navigate-button" onClick={() => this.handleNavigation(linkUrl)}>
        {buttonText}
      </button>
    );
  }

  handleSecondaryButtonElm = (item: {
    secondary_button_text: string,
    secondary_link_url: string,
    secondary_button_text_1: string,
    secondary_link_url_1: string,
    external_link: boolean,
    external_link_1: boolean
  }, sectionType: string = "") => {
  
    const isExternalLink = sectionType ? item.external_link_1 : item.external_link;
    const buttonText = sectionType ? item.secondary_button_text_1 : item.secondary_button_text;
    const linkUrl = sectionType ? item.secondary_link_url_1 : item.secondary_link_url;
  
    return isExternalLink ? (
      <a className="secondarybtn" data-testid="secondary-navigate-button" href={linkUrl}>
        {buttonText}
      </a>
    ) : (
      <button className="secondarybtn" data-testid="secondary-navigate-button" onClick={() => this.handleNavigation(linkUrl)}>
        {buttonText}
      </button>
    );
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    

    return (
      <StyledWrapper>
        <>
        {this.state.message ? <PageNotFound />:
        <section className="containerStyles">
          <Box className="header">
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Box className="content">
            <Box className="leftSide">
              <Box className="image">
              {this.state?.resStats?.image_urls?.image ? (
                <img
                  src={this.state?.resStats?.image_urls?.image}
                  alt="GroupWellness"
                  onError={(e) => {
                    e.currentTarget.style.display = 'none'; 
                  }}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <ImageLoader loading={true} />
              )}
              </Box>
              <Box className = "contentWrapper">
              <h2 className="heading">{this.state?.resStats?.main_title}</h2>
              <p className="paragraph"
                dangerouslySetInnerHTML={{__html: this.state?.resStats?.main_body}}
              />
              {this.state.resStats?.primary_links_attributes?.map((item:any)  => 
                this.handleButtonElm(item)
              )}
              {this.state.resStats?.secondary_links_attributes && this.state.resStats?.secondary_links_attributes.map((item: { secondary_button_text: string; secondary_link_url: string; secondary_button_text_1: string; secondary_link_url_1: string; external_link: boolean; external_link_1: boolean; }) => 
                    this.handleSecondaryButtonElm(item)
                  )}
              </Box>
            </Box>
            <Box className="rightSide">
              <Box className="image">
             {this.state?.resStats?.image_urls?.image1 ? <img src={this.state?.resStats?.image_urls?.image1} alt="Therapy" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
             : <ImageLoader loading={true} />}
             
              </Box>
              <Box className = "contentWrapper">
              <h2 className="headingRight">{this.state?.resStats?.main_title_1}</h2>
              <p 
                className="paragraphRight"
                dangerouslySetInnerHTML={{__html: this.state?.resStats?.main_body_1}}
              />
              {this.state.resStats?.primary_links_section2_attributes?.map(item  => 
                this.handleButtonElm(item, "section1")
              )}
              {this.state.resStats?.secondary_links_section2_attributes && this.state.resStats?.secondary_links_section2_attributes.map((item: { secondary_button_text: string; secondary_link_url: string; secondary_button_text_1: string; secondary_link_url_1: string; external_link: boolean; external_link_1: boolean; }) => 
              this.handleSecondaryButtonElm(item, "section1")
            )}
              </Box>
            </Box>
          </Box>
        </section>
        }</>
      </StyledWrapper>

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  width: "100%",
  backgroundColor: "#111",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 'calc(0px + 100vh)',
  "& .containerStyles": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    minHeight: "78px",
    margin: "auto",
    flex: 1
  },

  "& .contentWrapper": {
    width: '100%',
    maxWidth: "560px",
    marginTop: "55.92px",
    margin: "55.92px auto auto auto",
  },
  "& .header": {
    height: "auto",
    minHeight: "78px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff"
  },
  "& .logoImg": {
    marginLeft: "144px",
  },

  "& .content": {
    display: "flex",
    width: "100%",
    height: "auto",
    flex: 1
  },

  "& .leftSide": {
    height: "auto",
    width: "100%",
    maxWidth: "50%",
    backgroundColor: "#1A4A42",
    textAlign: "center",
    padding: "40px",
    paddingBottom: "160px"
  },

  "& .rightSide": {
    height: "auto",
    width: "100%",
    maxWidth: "50%",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    padding: "40px",
    paddingBottom: "160px",
    borderTop: "1px solid #D6D3D1",
  },
  "& .secondarybtn": {
    display: "flex",
    justifyContent: "center",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    color: "#E46760",
    border: "none",
    background: "none",
    cursor: "pointer",
    marginTop: '33px'
  },
  "& .heading": {
    fontFamily: "Raleway",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "60px",
    letterSpacing: "0.5px",
    textAlign: "center",
    marginBottom: "16px",
    marginTop: "0"
  },

  "& .paragraph": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: 0,
    "& p": {
      margin: 0
    }
  },

  "& .headingRight": {
    fontFamily: "Raleway",
    color: "#2C2524",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "60px",
    letterSpacing: "0.5px",
    textAlign: "center",
    marginBottom: "16px",
    marginTop: "0"
  },

  "& .paragraphRight": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#2C2524",
    margin: 0,
    "& p": {
      margin: 0
    }
  },
  "& .leftSide, & .rightSide": {
    display: "flex",
    flexDirection: "column",
    gap: "55px",
    paddingTop: '81px',
    "& .image": {
      width: "100%",
      height: "auto",
      maxWidth: "373.89px",
      maxHeight: "337.08px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flex: 1
    }
  },
  "& .button": {
    width: "100%",
    maxWidth: "540px",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    borderRadius: "24px",
    backgroundColor: "#E46760",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16.5px 0",
    border: "none",
    cursor: "pointer",
    margin: "46px auto 0",
  },

  "@media screen and (max-width: 1480px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },

    "& .heading": {
      fontSize: "40px",
    },
    "& .headingRight": {
      fontSize: "40px",
    },
    "& .paragraph": {
      fontSize: "18px",
    },
    "& .paragraphRight": {
      fontSize: "18px",
    },
  },

  "@media screen and (max-width: 1100px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
    "& .heading": {
      fontSize: "38px",
    },
    "& .headingRight": {
      fontSize: "38px",
    },
    "& .paragraph": {
      fontSize: "18px",
    },
    "& .paragraphRight": {
      fontSize: "18px",
    },
  },

  "@media screen and (max-width: 991px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
    "& .heading": {
      fontSize: "26px",
    },
    "& .headingRight": {
      fontSize: "26px",
    },
    "& .paragraph": {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.5",
    },
    "& .paragraphRight": {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.5",
    },
    "& .leftSide, & .rightSide": {
      padding: "30px",
    },
  },

  "@media screen and (max-width: 767px)": {
    "& .leftSide, & .rightSide": {
      maxWidth: "100%",
    },
    "& .content": {
      flexWrap: "wrap",
    },
    "& .button": {
      fontSize: "18px",
    },
  },
});

const webStyles = {

};


export default LandingPage;
// Customizable Area End