import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Select,
  TextField, Divider, MenuItem, FormControl
} from "@mui/material";
import { Event } from "@mui/icons-material";
import DatePicker from "react-multi-date-picker";
import { HeaderLogoImg } from "./assets";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { setStorageData } from "../../../framework/src/Utilities";
import { ConditionalRenderer, ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
import StepperData from '../../../components/src/StepperData.web';
import PhoneInput from 'react-phone-input-2'
import TermsConditionsEdit from "../../termsconditions/src/TermsConditionsEdit.web";
import SignupFooter from "../../../components/src/SignupFooter.web"
// Customizable Area End

import WellnessJourneyController, { Props } from "./WellnessJourneyController";
export default class WellnessJourney extends WellnessJourneyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    phone_number: Yup.string().when([], {
      is: () => this.state.formStats?.phone_number_req === true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    email: Yup.string()
      .email("Please enter a valid email")
      .when([], {
        is: () => this.state.formStats?.email_req === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }),
    contact_method: Yup.mixed().test(
      "at-least-one-checked",
      "Required",
      function () {
        const { phone_checkbox, email_checkbox, sms_checkbox } = this.parent;
        return phone_checkbox || email_checkbox || sms_checkbox;
      }
    ),
  });
  renderError = (touched: any, fieldStats: any) => {
    return ConditionalRenderer(Boolean(touched && fieldStats), <div className="error">{fieldStats}</div>, '')
  }
  // Customizable Area End

  render() {
    const paramId = this.props.navigation.getParam("navigationBarTitleText");
    return (
      // Customizable Area Start
      <>
        <section style={{ borderLeft: "32px solid #1A4A42" }}>
          <StyledWrapperForWellness>

            <Box className="headerLogo">
              <img className="logoImg" alt="HeaderLogoImgs" src={HeaderLogoImg} />
            </Box>
            <Divider />
            <Box className="headerSteppers">
              <StepperData
                key={`stepper${this.state.formStats.stage_type}`}
                listData={this.state.formStats.progress_bars}
                activeStep={this.state.formStats.stage_type}
              />
            </Box>

            <Formik
              initialValues={{
                phone_number: "",
                landline_number: "",
                email: "",
                phone_checkbox: false,
                email_checkbox: false,
                sms_checkbox: false,
                contact_method: [],
                reason_for_signing_up: "",
                relationship_to_patient: "",
                dateOfBirth: "",
              }}
              data-test-id="formikWrapper"
              validationSchema={this.validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setStorageData("values", JSON.stringify(values));
                setStorageData("Param Value", paramId);
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                <Box height="auto">
                  <Form onSubmit={handleSubmit}>
                    <Box className="main-section">
                      <h2 className="questions">
                        {this.state.formStats.form_header}
                      </h2>
                      <p className="information">
                        {this.state.formStats.form_description}
                      </p>

                      <Divider sx={{
                        borderColor: '#000000',
                        width: '55%',
                        margin: '0 auto'
                      }} />

                      <Box className="box">
                        {
                          this.state.formStats.phone_number &&
                          <Box className="div-container">
                            <div className="heading">{this.state.formStats.phone_number_label}
                              {ConditionalRenderer(this.state.formStats.phone_number_req, <span className='required-text'>*</span>, '')}
                            </div>
                            <Box className="inputField">
                              <PhoneInputWrapper>
                                <PhoneInput
                                  country={'us'}
                                  placeholder='Enter your phone number'
                                  value={values.phone_number}
                                  data-testid="mobile_number"
                                  onChange={(phone) => {
                                    setFieldValue('phone_number', phone);
                                    this.setState({ therapyPhoneNo: phone });
                                  }}
                                />
                              </PhoneInputWrapper>
                              {ConditionalRenderer(Boolean(errors.phone_number),
                                <div className="error-container">
                                  {ConditionalRenderer(Boolean(touched.phone_number),
                                    <div className="error">
                                      {errors.phone_number}
                                    </div>, ''
                                  )}
                                </div>, ''
                              )}
                            </Box>
                            <Box className="checkboxContainer">
                              <label style={webstyle.checkboxLabel}>
                                <input
                                  type="checkbox"
                                  checked={this.state.landline_no}
                                  onChange={this.handleLandlineToggles}
                                  style={webstyle.checkboxInput}
                                  data-test-id="landline"
                                />
                                <span style={webstyle.checkboxCustom(this.state.landline_no)}>
                                  {<span style={webstyle.checkmark}></span>}
                                </span>
                                <span className="checkboxText">I don't have a mobile - add a landline</span>
                              </label>
                            </Box>
                            {
                              ConditionalRenderer(this.state.landline_no, <Box className="inputField">
                                <PhoneInputWrapper>
                                  <PhoneInput
                                    country={'us'}
                                    placeholder='Enter your phone number'
                                    value={values.landline_number}
                                    data-test-id="landline_number"
                                    onChange={(landline_no: any) => {
                                      setFieldValue('landline_number', landline_no);
                                      this.setState({ therapyLadlineNo: landline_no });
                                    }} />
                                </PhoneInputWrapper>
                              </Box>, '')
                            }
                          </Box>
                        }

                        {
                          this.state.formStats.email &&
                          <Box className="div-container">
                            <div className="heading">Email
                              {ConditionalRenderer(this.state.formStats.email_req, <span className='required-text'>*</span>, '')}
                            </div>
                            <Box className="inputField">
                              <Field
                                name="email"
                                className="emailField inputFields"
                                onChange={(e: any) => {
                                  handleChange(e);
                                  this.setState({ therapyEmail: e.target.value })
                                }}
                                placeholder="Enter Email address"
                                data-test-id="txtInput2"
                                as={TextField}
                                value={values.email}
                              />
                            </Box>
                          </Box>
                        }

                        {this.state.formStats.contact_method && (
                          <>
                            <div className="heading">
                              Preferred Contact Method{" "}
                              {ConditionalRenderer(this.state.formStats.contact_method_req, <span className='required-text'>*</span>, '')}
                              <span className="Select">
                                (select all that apply)
                              </span>
                            </div>
                            <Box className="checkBoxGroup">
                              <label style={webstyle.checkboxLabel} >
                                <input
                                  type="checkbox"
                                  data-test-id="phone1"
                                  onChange={(e: any) => {
                                    setFieldValue("phone_checkbox", e.target.checked);
                                    this.handleCheckboxChange("phone", e.target.checked);
                                  }}
                                  name="phone_checkbox"
                                  checked={values.phone_checkbox}
                                  style={webstyle.checkboxInput}
                                />
                                <span
                                  style={webstyle.checkboxCustom(values.phone_checkbox)}>
                                  {<span style={webstyle.checkmark}></span>}
                                </span>
                                <span className="phoneClass">Phone</span>
                              </label>
                              <label style={webstyle.checkboxLabelEmail}>
                                <input
                                  type="checkbox"
                                  data-test-id="email1"
                                  style={webstyle.checkboxInput}
                                  onChange={(e: any) => {
                                    setFieldValue("email_checkbox", e.target.checked);
                                    this.handleCheckboxChange("email", e.target.checked);
                                  }}
                                  name="email_checkbox"
                                  checked={values.email_checkbox}
                                />
                                <span
                                  style={webstyle.checkboxCustom(values.email_checkbox)}>
                                  {<span style={webstyle.checkmark}></span>}
                                </span>
                                <span className="phoneClass">Email</span>
                              </label>
                              <label style={webstyle.checkboxLabel}>
                                <input
                                  type="checkbox"
                                  data-test-id="sms1"
                                  style={webstyle.checkboxInput}
                                  onChange={(e: any) => {
                                    setFieldValue("sms_checkbox", e.target.checked);
                                    this.handleCheckboxChange("sms", e.target.checked);
                                  }}
                                  name="sms_checkbox"
                                  checked={values.sms_checkbox}
                                />
                                <span
                                  style={webstyle.checkboxCustom(values.sms_checkbox)}>
                                  {<span style={webstyle.checkmark}></span>}
                                </span>
                                <span className="phoneClass">SMS</span>
                              </label>
                            </Box>
                          </>
                        )}
                        <Box className="div-container">
                          <div className="heading">Free Text
                            {ConditionalRenderer(this.state.formStats.email_req, <span className='required-text'>*</span>, '')}
                          </div>
                          <Box className="inputField">
                            <Field
                             data-test-id="txtInput2"
                             as={TextField}
                             value={values.email}
                             className="emailField inputFields"
                             placeholder="Enter Email address"
                              onChange={(e: any) => {
                                handleChange(e);
                                this.setState({ therapyEmail: e.target.value })
                              }}
                              name="email"
                             
                            />
                            {ConditionalRenderer(Boolean(errors.email),
                              <div className="error-container">
                                {ConditionalRenderer(Boolean(touched.email),
                                  <div className="error">
                                    {errors.email}
                                  </div>, ''
                                )}
                              </div>, ''
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <div className="heading">Date
                            {ConditionalRenderer(true,<span className='required-text'>*</span>,'')}
                          </div>

                          <Box style={{ position: 'relative' }} ref={this.dropDownRef}>
                            <DatePicker
                               ref={this.datePickerRef}
                               placeholder="MM-DD-YYYY"
                               data-testid="date_of_birth"
                               format="MM-DD-YYYY"
                               name="dateOfBirth"
                               required={true}
                               className="datePickerStyle"
                               value={values.dateOfBirth}
                               onChange={(date) => {
                                 setFieldValue("dateOfBirth", date?.format());
                                 this.setState({ dateValue: date?.format() });
                               }}
                               style={{
                                 width: '600px',
                                 borderRadius: '24px',
                                 border: '1px solid #E6C79C',
                                 padding: '10px 40px 10px 10px',
                                 height: "100%",
                                 maxHeight: "44px",
                                 fontFamily: "Poppins",
                                 fontWeight: 400,
                                 fontSize: "16px",
                                 lineHeight: "24px",
                               }}
                            />
                            <Event style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              pointerEvents: 'none',
                            }} className="calendar-icon" />
                          </Box>
                          {this.renderError(touched.dateOfBirth as boolean, errors.dateOfBirth as string)}
                        </Box>
                        <Box className="full-name">
                          <div className="heading">State
                            <span className='require-text'>*</span>
                          </div>
                          <FormControl className="inputFieldLast1" fullWidth>
                            <Field
                              as={Select}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    border: "1px solid #E6C79C",
                                    borderRadius: "0px 0px 24px 24px",
                                  },
                                },
                              }}
                              sx={{
                                border: "1px solid #E6C79C",
                                borderRadius: "0px 0px 24px 24px",
                                borderTop: "none",
                                padding: "12px",
                                "& fieldset": {
                                  border: "none",
                                },
                                "& .MuiSelect-select": {
                                  borderRadius: "16px",
                                },
                              }}
                              onChange={(e: any) => {
                                handleChange(e);
                                this.setState({ patientRelation: e.target.value });
                              }}
                              name="relationship_to_patient"
                              value={values.relationship_to_patient}
                              data-test-id="relation"
                              displayEmpty
                              placeholder="Select One"
                              IconComponent={ResolveCondition(this.state.open, KeyboardArrowUpIcon, KeyboardArrowDownIcon)}
                            >
                              {["Newyork", "Londan", "Newyork", "Newyork"].map((item: string, index: number) => (
                                <MenuItem
                                  key={index}
                                  value={item}
                                  sx={{
                                    borderBottom: "1px solid #D6D3D1",
                                    fontWeight: 400,
                                    padding: "8px 12px 8px 16px",
                                    height: "40px",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    "&:hover": {
                                      backgroundColor: "#e0e0e0",
                                    },
                                  }}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Field>
                            {ConditionalRenderer(Boolean(errors.relationship_to_patient),
                              <div className="error-container">
                                {ConditionalRenderer(Boolean(touched.relationship_to_patient),
                                  <div className="error">
                                    {errors.relationship_to_patient}
                                  </div>, ''
                                )}
                              </div>, ''
                            )}
                          </FormControl>

                        </Box>

                        <Box>
                          <Box>
                            <div className="heading">Why are you signing up on behalf of someone else?
                              <span className='require-text'>*</span>
                            </div>
                            <FormControl className="inputFieldLast1" fullWidth>
                              <Field
                                onChange={(e: any) => {
                                  handleChange(e);
                                  this.setState({ patientReasons: e.target.value });
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      border: "1px solid #E6C79C",
                                      borderRadius: "0px 0px 24px 24px",
                                    },
                                  },
                                }}
                                sx={{
                                  border: "1px solid #E6C79C",
                                  borderRadius: "0px 0px 24px 24px",
                                  padding: "12px",
                                  borderTop: "none",
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "& .MuiSelect-select": {
                                    borderRadius: "16px",
                                  },
                                }}
                                name="reason_for_signing_up"
                                data-test-id="reasons"
                                as={Select}
                                placeholder="Select One"
                                displayEmpty
                                IconComponent={ResolveCondition(this.state.open, KeyboardArrowUpIcon, KeyboardArrowDownIcon)}
                              >
                                {["Reason1", "Reason2", "Reason3", "Reason4", "Reason5", "Other"].map((item: string, index: number) => (

                                  <MenuItem
                                    sx={{
                                      fontWeight: 400,
                                      borderBottom: "1px solid #D6D3D1",
                                      padding: "8px 12px 8px 16px",
                                      height: "40px",
                                      fontFamily: "Poppins",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      "&:hover": {
                                        backgroundColor: "#e0e0e0",
                                      },
                                    }}
                                    key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Field>
                              {ConditionalRenderer(Boolean(errors.reason_for_signing_up),
                                <div className="error-container">
                                  {ConditionalRenderer(Boolean(touched.reason_for_signing_up),
                                    <div className="error">
                                      {errors.reason_for_signing_up}
                                    </div>, ''
                                  )}
                                </div>, ''
                              )}
                            </FormControl>
                          </Box>
                          <Box>
                            {values.reason_for_signing_up === "Other" && (
                              <Box>
                                <FormControl className="otherInputFieldLast1" fullWidth>
                                  <Field
                                    name="other_reasons"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "16px",
                                        padding: "12px",
                                      },
                                    }}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <Box padding="0px 41.5px">
                            <Box className="checkboxContainerLast">
                              <label style={webstyle.checkboxLabel1}>
                                <input
                                  type="checkbox"
                                  style={webstyle.checkboxInput1}
                                  name="agree"
                                  data-test-id="agreeCheck"
                                  checked={this.state.agreeChecked}
                                  onChange={(e) =>
                                    this.handleAgreeChanges(e.target.checked)
                                  }
                                />
                                <span
                                  style={webstyle.checkboxCustom1(this.state.agreeChecked)}
                                >
                                  {<span style={webstyle.checkmark1}></span>}
                                </span>
                                <span className="phoneClass">
                                  I agree with the{" "}
                                  <u data-test-id="conditions"
                                    onClick={() => this.handleNavigateTermConditions("terms_and_condition", "patient")}
                                  >
                                    <i className="italic" style={{ cursor: "pointer" }}>Terms of Use</i>
                                  </u>{" "}
                                  <span className="phoneClass">and{" "}</span>
                                  <u data-test-id="condition"
                                    onClick={() => this.handleNavigateTermConditions("privacy_policy", "patient")}
                                  >
                                    <i className="italic" style={{ cursor: "pointer" }}>Privacy Policy</i>
                                  </u>
                                </span>
                              </label>
                              {
                                this.state.modalOpen && <TermsConditionsEdit navigation={undefined} id={""} modalOpen={this.handleNavigateTermConditions} pageType={this.state.apiPageType} role={this.state.role} states={this.state.modalOpen} />
                              }
                            </Box>
                            <Box className="checkboxContainerLast">

                              <label
                                style={webstyle.checkboxLabel1}
                              >
                                <input
                                  type="checkbox"
                                  style={webstyle.checkboxInput1}
                                  name="agree"
                                  data-test-id="consentAgreeCheck"
                                  checked={this.state.consentChecked}
                                  onChange={(e) =>
                                    this.handleConsentChange(e.target.checked)
                                  }
                                />
                                <span
                                  style={webstyle.checkboxCustom1(this.state.consentChecked)}
                                >
                                  {<span style={webstyle.checkmark1}></span>}
                                </span>
                                <span className="phoneClass">
                                  I have the consent of the patient
                                </span>
                              </label>
                            </Box>
                          </Box>


                          <Box marginBottom="28px">
                            <button
                              onClick={this.postTherapySignupApi}
                              className={`button-continue ${ResolveCondition(
                                true,
                                "active",
                                "inactive"
                              )}`}
                              disabled={!this.state.selectedValue}
                              data-test-id="submitBtn"
                              type="submit"
                            >
                              Continue
                            </button>
                          </Box>


                        </Box>
                        <Box className="loginClass">
                          <u className="content">Already have an account? Log in</u>
                        </Box>

                      </Box>

                    </Box>
                  </Form>

                </Box>
              )}

            </Formik>

            <Box marginTop="468px" marginLeft="115px">
              <div
                data-test-id="submitBtn"
                className="greyColor"
                onClick={() => {
                  this.props.navigation.goBack();
                }}
              >
                Back
              </div>

            </Box>
          </StyledWrapperForWellness>
          <Box marginTop="18%">

            <SignupFooter />
          </Box>
        </section>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapperForWellness = styled("div")({
  "& .full-name": {
    marginTop: "32px"
  },
  "& .inputFieldLast1 .MuiOutlinedInput-root": {

    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .inputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 30px",
    borderRadius: "24px",
    maxWidth: "100%",
    border: "0px solid #E6C79C",
    width: "100%",
    maxHeight: "44px",
  },
  "& .inputFieldLast1": {
    maxHeight: "72px",
    borderRadius: "24px",
    maxWidth: "100%",
    width: "100%",
    marginBottom: "24px",
    "& .MuiSelect-select": {
      marginLeft: "-5%",
      display: "flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%",
      display: "flex"
    }
  },
  "& .otherInputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 4px",
    borderRadius: "24px",
    maxWidth: "100%",
    border: "0px solid #E6C79C",
    width: "100%",
    maxHeight: "44px",
  },
  "& .otherInputFieldLast1": {
    maxHeight: "72px",
    borderRadius: "24px",
    maxWidth: "100%",
    width: "100%",
    marginBottom: "9px",
    "& .MuiSelect-select": {
      marginLeft: "-5%",
      display: "flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%",
      display: "flex"
    }
  },
  "& .otherInputFieldLast1 .MuiOutlinedInput-root": {

    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .date-picker-container .inputFields": {
    height: "100%",
    width: "100%",
    maxHeight: "44px",
    maxWidth: "288px",
    background: "#FFFFFF",
  },
  "& .greyColor": {
    color: "#807C7C",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    cursor: "pointer",
  },
  "& .date-picker-container .rmdp-top-class": {
    backgroundColor: "#FFFFFF",
  },
  "& .date-picker-container .rmdp-container": {
    // display: "block !important",
    width: "100% !important"
  },
  "& .date-picker-container .inputFields input::placeholder": {
    fontSize: "15px",
    fontFamily: "Poppins",
  },
  "& .date-picker-container": {
    "position": "relative",
    "width": "100%",
    "maxWidth": "288px",
    "height": "44px",
    // border :"2px solid black"
  },

  "& *": {
    boxSizing: "border-box",
  },
  "& .required-text": {
    textFillColor: "#DC2626",
    WebkitTextFillColor: "#DC2626",
    margin: "3px"
  },
  "& .MuiFormControl-root.MuiTextField-root.emailField.inputFields": {
    width: "100% !important"
  },
  "& .emailField .MuiOutlinedInput-root": {
    width: "100%",
    height: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
    borderColor: "#E6C79C",

    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "& .MuiInputBase-input": {
      height: "100%",
    }
  },
  "& .MuiCheckbox-root.Mui-checked svg": {
    color: "#1A4A42",
    borderRadius: "4px",
  },
  "& .headerSteppers": {
    "display": "flex",
    "paddingTop": "32px",
    "justifyContent": "center",
  },
  "& .MuiCheckbox-root svg": {
    borderRadius: "10px",
  },
  "& .PhoneInputInput::placeholder, & .MuiInputBase-input::placeholder": {
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  "& .header .custom-step-connector .MuiStepConnector-root": {
    display: "none",
  },
  "& .header .custom-step-connector .MuiStepper-horizontal": {
    justifyContent: "space-between",
    position: "relative",
  },
  "& .header .custom-step-connector .MuiSvgIcon-root": {
    borderRadius: "10px",
  },
  "& .inputField .inputFields .MuiInputBase-root": {
    width: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px"
  },
  "& .inputField .inputFields .MuiOutlinedInput-root": {
    width: "100%",
    maxHeight: "42px",
    borderRadius: "24px",
    borderColor: "#E6C79C",
    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
  },
  "& .header .custom-step-connector .MuiStepLabel-iconContainer::after":
  {
    height: "3px",
    backgroundColor: "#A8A29E",
    content: `""`,
    position: "absolute",
    width: "239px",
    left: "44px",
    top: "10px",
  },
  "& .Select": {
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#78716C",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .error-container": {
    // minHeight: "20px",
  },
  "& .error": {
    color: "#DC2626",
    marginTop: "2px",
    textAlign: "initial",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
  },
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    width: "100%",
  },
  "& .inputField .inputFields .MuiInputBase-root.MuiOutlinedInput-root":
  {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
  },
  "& .italic": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textDecoration: "underline",
    color: "#1A4A42",
    textDecorationColor: "green",
  },
  "& .content": {
    fontWeight: 700,
    color: "#E46760",
    fontSize: "20px",
    fontFamily: "Raleway",
    lineHeight: "23.48px"
  },
  "& .div-container": {
    marginBottom: "24px",
  },

  "& .checkBoxGroup": {
    maxWidth: "291px",
    display: "flex",
    alignItems: "center",
    maxHeight: "22px",
    marginTop: "6px",
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "-10px",
    marginBottom: "24px"
  },
  "& .phoneClass": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px"
  },
  "& .datePickerStyle":{
    "& .rmdp-day":{
      color:"#0F172A",
      marginBottom:"6px"
    },
    "& .rmdp-week-day":{
        color:"#64748B",
        marginLeft:"6px",
        marginBottom:"8px"
    },
    "& .rmdp-header-values":{
      fontFamily:"Inter",
      fontWeight:700,
      lineHeight:"22px",
      fontSize:"14px"
    },
    // "& .rmdp-calendar":{
    //   width:"364px",
    //   height:"344px"
    // },
    "& .rmdp-day-picker":{
      display:"inline"


    }
  },

  "& .checkBoxGroup.active": {
    backgroundColor: "#000000",
  },
  "& .checkboxContainerLast": {
    display: "flex",
    marginTop: "24px",
    alignItems: "center",
  },
  "& .checkboxContainer": {
    display: "flex",
    alignItems: "center",
    marginTop: "4px"
  },
  "& .upperCheckbox": {
    borderRadius: "20px"
  },
  '& .checkboxText': {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .heading": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",
  },
  "& .box": {
    height: "100%",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "340px",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    marginTop: "32px",
  },
  "& .headerLogo": {
    height: "auto",
    width: "100%",
    minHeight: "78px",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .footerRight": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  "& .label-700-20": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  "& .main-section": {
    // height: "100vh",
    textAlign: "center",
    marginTop: "30px"
  },
  "& .custom-step-connector": {
    maxWidth: "600px",
    width: "100%",
  },
  "& .header .custom-step-connector .MuiStepLabel-labelContainer": {
    alignItems: "center",
    flexDirection: "column",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42;",
  },
  "& .questions": {
    fontWeight: 600,
    fontFamily: "Raleway",
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    maxWidth: "700px",
    margin: "auto",
  },
  "& .information": {
    maxWidth: "600px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "32px",
    marginBottom: "32px"
  },
  "& .callButton": {
    borderRadius: "16px",
    background: "#ffffff",
    border: "1px solid #E46760",
    padding: "5px 10px",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #E7E5E4",
    marginTop: "77.78px",
    padding: "0px 141px",
    minHeight: "96px",
    "@media(max-width:750px)": {
      padding: "0px 0px",
    },
  },
  "& .emergencyIcon": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
  },
  "& .button-continue": {
    border: "none",
    maxWidth: "600px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    cursor: "pointer !important",
    background: "#E46760",
    marginTop: "32px",
    marginBottom: "28px",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23.48px"
  },
  "& .button-continue.active": {
    border: "none",
    background: "#E46760",
    cursor: "pointer",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
  },

  "& .button-continue.inactive": {
    border: "none",
    background: "#D6D3D1",
    cursor: "not-allowed",
    color: "#FFFFFF",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    height: "auto",
    minHeight: "140px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  "@media screen and (max-width:1200px)": {
    "& .logoImg": {
      marginLeft: "50px",
    },
  },
  "@media screen and (max-width:767px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
  },
});

const PhoneInputWrapper = styled(Box)({
  "& .form-control": {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    paddingLeft: "15px",
    color: "#36403B",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "24px",
    border: "1px solid #E6C79C"
  },
  "& .form-control::placeholder": {
    fontSize: "15px",
  },
  "& .selected-flag": {
    display: "none"
  },
  "& .flag-dropdown ": {
    border: "none"
  }
});
const webstyle = {
  container: {
    fontFamily: "Arial, sans-serif",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
  },
  checkboxLabelEmail: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    margin: "0px 24px"
  },
  checkboxInput: {
    display: "none",
  },
  checkboxCustom: (isChecked: any): React.CSSProperties => ({
    width: "20px",
    height: "20px",
    border: "1px solid #78716C",
    borderRadius: "6px",
    marginRight: "10px",
    display: "inline-block",
    position: "relative",
    backgroundColor: isChecked ? "#1A4A42" : "transparent",
  }),
  checkmark: {
    position: "absolute" as "absolute", // Explicitly setting the position type
    top: "3px",
    left: "5px",
    width: "6px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)",
    opacity: 1,
  } as React.CSSProperties,
  linkStyle: {
    color: "#00796b",
    textDecoration: "underline",
  },
  checkboxLabel1: {
    display: "flex",
    cursor: "pointer",
    fontSize: "14px",
    alignItems: "center",
  },
  checkboxInput1: {
    display: "none",
  },
  checkboxCustom1: (isChecked: any): React.CSSProperties => ({
    width: "20px",
    height: "20px",
    border: "1px solid #78716C",
    display: "inline-block",
    position: "relative",
    backgroundColor: isChecked ? "#1A4A42" : "transparent",
    borderRadius: "6px",
    marginRight: "10px",
  }),
  checkmark1: {
    width: "8px",
    height: "12px",
    border: "solid white",
    left: "5px",
    position: "absolute" as "absolute",
    top: "1px",
    transform: "rotate(45deg)",
    borderWidth: "0 2px 2px 0",
    opacity: 1,
  } as React.CSSProperties,
};


// Customizable Area End